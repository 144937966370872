'use client';

import React, { useState } from 'react';

import {
    Flex,
    VStack,
    Stack,
    useBreakpointValue,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { GiHamburgerMenu } from 'react-icons/gi';

import Logo from '@assets/Logo2.svg';
import useAuthSelector from '@hooks/useAuthSelector';
import { paths } from '@routes/path';

import AccountInfo from './AccountInfo';
import NavButtons from './NavButtons';
import NavLinks from './NavLinks';

const PublicHeader = () => {
    const { isAuthenticated } = useAuthSelector();
    const isDesktopView = useBreakpointValue({ base: false, sm: false, md: false, lg: true });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <>
            {isDesktopView ? (
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    py={3}
                    px={10}
                    width="100%"
                    bgColor="headerBg"
                    position="sticky"
                    top={0}
                    zIndex={100}
                >
                    <Flex width="100%">
                        <Link href={paths.general.home}>
                            <Image src={Logo} alt="logo" />
                        </Link>
                    </Flex>
                    <Flex gap={5} width="100%">
                        <NavLinks />
                    </Flex>
                    {isAuthenticated ? (
                        <Flex alignItems="center" gap={6} width="100%" justify="end">
                            <AccountInfo />
                        </Flex>
                    ) : (
                        <Flex gap={4} width="100%" justifyContent="end">
                            <NavButtons />
                        </Flex>
                    )}
                </Flex>
            ) : (
                <>
                    <Stack
                        bgColor="headerBg"
                        position="sticky"
                        top={0}
                        zIndex={100}
                        py={4}
                        px={4}
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Flex width="100%">
                            <Link href={paths.general.home}>
                                <Image src={Logo} alt="logo" />
                            </Link>
                        </Flex>
                        <Flex gap={2} align="center">
                            {isAuthenticated && (
                                <Flex alignItems="center">
                                    <AccountInfo />
                                </Flex>
                            )}
                            <GiHamburgerMenu size={30} onClick={toggleDrawer} />
                        </Flex>
                    </Stack>
                    <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <VStack spacing={4} p={6}>
                                <NavLinks onClose={toggleDrawer} />
                                {!isAuthenticated && <NavButtons />}
                            </VStack>
                        </DrawerContent>
                    </Drawer>
                </>
            )}
        </>
    );
};

export default PublicHeader;
