import React from 'react';

import { Stack } from '@chakra-ui/react';

import Footer from '@components/nav-section/horizontal/Footer';
import PublicHeader from '@components/nav-section/horizontal/PublicHeader';

interface PublicLayoutProps {
    children: React.ReactNode;
}

const PublicLayout = ({ children }: PublicLayoutProps) => (
    <Stack gap={0}>
        <PublicHeader />
        <Stack minHeight="80vh" px={{ base: 2, sm: 5, md: 10 }}>
            {children}
        </Stack>
        <Footer />
    </Stack>
);

export default PublicLayout;
